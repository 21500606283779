<template>
  <div class="courses_block">
    <Slider />
    <div class="courses_sort_filter_block">
      <div class="courses_sort_filter_back">
        <div class="courses_sort_filter_container">
          <div class="courses_filter_block">
<!--            <div class="courses_filter_container">-->
<!--              <div class="courses_filter_label">{{ $t('Sort_and_filter') }}</div>-->
<!--              <div class="courses_list_sorting">-->
<!--&lt;!&ndash;                <div class="courses_list_sorting_label" @click="showSortingBlock = !showSortingBlock">{{ $t('Sorting') }}</div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="courses_list_radio_block" v-show="showSortingBlock">&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="courses_radio_item">&ndash;&gt;-->
<!--&lt;!&ndash;                    <input type="radio" name="sort" id="newest" class="courses_list_radio" disabled/>&ndash;&gt;-->
<!--&lt;!&ndash;                    <label for="newest" class="radio_label">{{ $t('New_ones_first') }}</label>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="courses_radio_item">&ndash;&gt;-->
<!--&lt;!&ndash;                    <input type="radio" name="sort" id="popular" class="courses_list_radio" disabled/>&ndash;&gt;-->
<!--&lt;!&ndash;                    <label for="popular" class="radio_label">{{ $t('Popular') }}</label>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="courses_radio_item">&ndash;&gt;-->
<!--&lt;!&ndash;                    <input type="radio" name="sort" id="rating" class="courses_list_radio" disabled/>&ndash;&gt;-->
<!--&lt;!&ndash;                    <label for="rating" class="radio_label">{{ $t('By_rating') }}</label>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--              </div>-->
<!--              <div class="courses_list_sorting">-->
<!--                <div class="courses_list_sorting_label" @click="showCoursesDurationBlock = !showCoursesDurationBlock">{{ $t('Course_duration') }}</div>-->
<!--                <div class="courses_list_radio_block" v-show="showCoursesDurationBlock">-->
<!--                  <div class="courses_radio_item">-->
<!--                    <input type="radio" name="filter" id="8" class="courses_list_radio" disabled/>-->
<!--                    <label for="8" class="radio_label">8</label>-->
<!--                  </div>-->
<!--                  <div class="courses_radio_item">-->
<!--                    <input type="radio" name="filter" id="36" class="courses_list_radio" disabled/>-->
<!--                    <label for="36" class="radio_label">36</label>-->
<!--                  </div>-->
<!--                  <div class="courses_radio_item">-->
<!--                    <input type="radio" name="filter" id="40" class="courses_list_radio" disabled/>-->
<!--                    <label for="40" class="radio_label">40</label>-->
<!--                  </div>-->
<!--                  <div class="courses_radio_item">-->
<!--                    <input type="radio" name="filter" id="72" class="courses_list_radio" disabled/>-->
<!--                    <label for="72" class="radio_label">72</label>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
            <div class="courses_list">
              <div
                class="cursor-pointer"
                :class="{
                  'courses_list_item_new': course['new'] === '1',
                  'courses_list_item_star': course['featured'] === '1',
                  'courses_list_item_fire': course['fire'] === '1',
                  'courses_list_item': (course['new'] !== '1' && course['featured'] !== '1' && course['fire'] !== '1')
                }"
                v-for="course in courses"
                @click="goToCourse(course.slug)">
                <div class="courses_list_item_img" :style="'background-image: url(' + course.preview_image + ')'">
<!--                  <div class="courses_list_item_icon" :class="{-->
<!--                    'courses_list_item_icon_new': course['new'] === '1',-->
<!--                    'courses_list_item_icon_star': course['featured'] === '1',-->
<!--                    'courses_list_item_icon_fire': course['fire'] === '1',-->
<!--                  }"></div>-->
                </div>
                <div class="courses_list_item_desc">
                  <div class="courses_list_item_desc_text">
                    <div class="courses_list_item_title">{{ course.title }}</div>
                    <div class="courses_list_item_rate"></div>
                    <div class="courses_list_item_about">{{ course.instructor }}</div>
                    <div class="courses_list_item_date">{{course.enrollments}} {{ $t('Registration_for_the_course') }}</div>
                  </div>
                  <div class="courses_list_item_rating">
                    <div class="courses_list_item_rating_star">{{+course.avg_rating.toFixed(1)}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="courses_pagination">
              <MyPagination
                :totalPages="totalPages"
                :total="total"
                :perPage="perPage"
                :currentPage="currentPage"
                @pagechanged="onPageChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Slider from "@/views/Courses/Slider";
import MyPagination from "@/components/MyPagination";
export default {
  name: 'courses',
  components: {
    MyPagination,
    Slider,
  },
  data() {
    return {
      courses: [],
      showSortingBlock: true,
      showCoursesDurationBlock: true,
      currentPage: 1,
      perPage: 12,
      total: 0,
      totalPages: 0,
    }
  },
  methods: {
    getCourses(page) {
      let apiRoot = API_ROOT
      this.$http.get(`${apiRoot}/api/courses?page=${page}`)
          .then(res => {
            this.courses = res.body.data
            this.total = res.body.meta.total
            this.perPage = res.body.meta.per_page
            this.totalPages = res.body.meta.last_page
          })
          .catch(err => console.log(err))
          .finally(() => {

          })
    },
    goToCourse(slug) {
      this.$router.push({ name: 'course-details', params: { slug } })
    },
    onPageChange(page) {
      this.currentPage = page;
      this.getCourses(page)
    },
  },
  mounted() {
    this.getCourses(this.currentPage)
  }
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
