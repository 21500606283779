<template>
  <div class="courses_slider_block">
    <div class="courses_slider_container">
      <div class="courses_item_slider_arrow_left" @click="prevPage"/>
      <div class="courses_item_slider_arrow_right" @click="nextPage"/>
      <carousel v-if="courses.length" :per-page="1" navigationNextLabel="next" paginationActiveColor="#6F4189" paginationColor="white" :navigateTo="manualNavigation">
        <slide v-for="course in courses">
          <div class="courses_slider_item">
            <div class="courses_slider_title">{{$t('course_education')}}</div>
            <div class="courses_slider_info_block">
              <div v-if="course.featured == '1'" class="courses_slider_date">{{$t('Popular')}}</div>
              <div class="courses_slider_label">
                {{ course.title }}
              </div>
              <div class="courses_slider_text" v-html="course.short_detail"></div>
              <router-link :to="{ name: 'course-details', params: { slug: course.slug } }" class="courses_slider_btn_more"><span>{{$t("show_more")}}</span></router-link>
            </div>
          </div>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import {Carousel, Slide} from 'vue-carousel';
export default {
  name: 'Slider',
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      courses: [],
      manualNavigation : 0
    }
  },
  mounted() {
    this.getFeaturedCourses();
  },
  methods: {
    getFeaturedCourses() {
      let apiRoot = API_ROOT
      this.$http.get(`${apiRoot}/api/courses?featured=true`)
        .then(res => {
          this.courses = res.body.data
        })
    },
    nextPage() {
      if (this.manualNavigation >= this.courses.length - 1) {
        this.manualNavigation = 0
      } else this.manualNavigation = this.manualNavigation + 1
    },
    prevPage() {
      if (this.manualNavigation <= 0) {
        this.manualNavigation = this.courses.length - 1
      } else this.manualNavigation = this.manualNavigation - 1
    },
  }
}
</script>

<style scoped>

</style>
